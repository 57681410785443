/*::-webkit-scrollbar {*/
/*    width: 0px;*/
/*}*/

/*.container-2::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/

.container-2 {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    min-height: 250px !important;
    padding: 20px 0;
}

.card-2 {
    flex-shrink: 0;
    width: 300px;
    height: 200px;
    /*border-radius: 10px;*/
    border-color: transparent !important;
    margin-left: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    padding-left: 10px;
    padding-top: 10px;
}
