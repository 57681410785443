#treeWrapper {
  height: 500px;
  background: linear-gradient(to bottom, #c2e4fa, #cdd4d8); /* Existing gradient background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.node__label {
  fill: #fff; /* White text color for all nodes */
  font-size: 14px; /* Adjust the font size as needed */
  font-weight: bold; /* Adjust the font weight as needed */
  text-anchor: middle; /* Center the text horizontally in the node */
  
}

.node__root > circle {
  fill: #E74C3C; /* Red */
  stroke: #000000;
  r: 25;
}


.node__branch > circle {
  fill: #F1C40F; /* Yellow */
  stroke: #000000;
  r: 20;
}

.node__leaf > circle {
  fill: #27AE60; /* Green */
  stroke: #000000;
  r: 15;
}

.node__root > circle:hover,
.node__branch > circle:hover,
.node__leaf > circle:hover {
    stroke-width: 3;
    stroke: #ffffff;
    cursor: pointer;
}


