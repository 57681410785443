.my-masonry-grid {
    display: flex;
    margin-left: -30px;
    width: auto;
  }
  
  .my-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
  }
  