.custom__modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  border-radius: 20px;
}

.modal__content {
  /* overflow-y: auto; */
  max-height: 95vh;
  max-width: 75% !important;
  border-radius: 1rem;

}

.modal__content::-webkit-scrollbar {
  width: 12px !important;
}
