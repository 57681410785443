:root {
  --background-color: #0079bf; /* Trello blue */
  --board-color: #ebecf0; /* A light grey for board backgrounds */
  --card-color: #FFFFFF; /* Cards are usually white */
  --text-color: #34495E; /* A contrasted text color */
  --drop-down-color: #E2E4E6; /* Same as board color */
  --card-box-shadow: #aaa; /* Slight shadow for depth */
  --add-btn-hover: #006aa7; /* A bit darker blue for button hover */
  --modal-color: #FFFFFF; /* Modals can remain white for cleanliness */
  --task-hover: #f4f5f7; /* Slightly lighter color for hover effect */
}

[data-theme="dark"] {
  --background-color: #253341; /* A darkened blue */
  --board-color: #3b4b58;
  --card-color: #2b3a45;
  --card-box-shadow: #1a272e;
  --drop-down-color: #3b4b58;
  --text-color: #FFFFFF; /* White text on dark theme */
  --add-btn-hover: #1a2a38;
  --modal-color: #3b4b58;
  --task-hover: #1c2d3b;
}

body {
  margin: 0;
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background-color: var(--background-color);
}

code {
  font-family: "Courier New", source-code-pro, Menlo, Monaco, Consolas, monospace;
}

